var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[_vm.$style.container, _vm.open && _vm.$style.open]},[_c('nav',[_c('ul',{on:{"mouseout":function($event){_vm.symbol = null}}},[_c('li',{class:_vm.$style.item,on:{"mouseover":function($event){_vm.symbol = 'cine'}}},[_c('router-link',{attrs:{"to":"/#section-cine"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":function($event){_vm.open && _vm.playSound('cine', 'menu');
              _vm.$router.push(href);
              _vm.$emit('close');}}},[_vm._v(_vm._s(_vm.$t("sections.cine"))+" ")])]}}])})],1),_c('li',{class:_vm.$style.item,on:{"mouseover":function($event){_vm.symbol = 'tv'}}},[_c('router-link',{attrs:{"to":"/#section-tv"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":function($event){_vm.open && _vm.playSound('tv', 'menu');
              _vm.$router.push(href);
              _vm.$emit('close');}}},[_vm._v(_vm._s(_vm.$t("sections.tv"))+" ")])]}}])})],1),_c('li',{class:_vm.$style.item,on:{"mouseover":function($event){_vm.symbol = 'albums'}}},[_c('router-link',{attrs:{"to":"/#section-albums"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":function($event){_vm.open && _vm.playSound('albums', 'menu');
              _vm.$router.push(href);
              _vm.$emit('close');}}},[_vm._v(_vm._s(_vm.$t("sections.albums"))+" ")])]}}])})],1),_c('li',{class:_vm.$style.item,on:{"mouseover":function($event){_vm.symbol = 'sesiones'}}},[_c('router-link',{attrs:{"to":"/#section-sesiones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":function($event){_vm.open && _vm.playSound('sesiones', 'menu');
              _vm.$router.push(href);
              _vm.$emit('close');}}},[_vm._v(_vm._s(_vm.$t("sections.sessions"))+" ")])]}}])})],1),_c('li',{class:_vm.$style.item,on:{"mouseover":function($event){_vm.symbol = 'teatro'}}},[_c('router-link',{attrs:{"to":"/#section-teatro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":function($event){_vm.open && _vm.playSound('teatro', 'menu');
              _vm.$router.push(href);
              _vm.$emit('close');}}},[_vm._v(_vm._s(_vm.$t("sections.theater"))+" ")])]}}])})],1),_c('li',{class:_vm.$style.item,on:{"mouseover":function($event){_vm.symbol = 'contenido'}}},[_c('router-link',{attrs:{"to":"/#section-contenido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":function($event){_vm.open && _vm.playSound('contenido', 'menu');
              _vm.$router.push(href);
              _vm.$emit('close');}}},[_vm._v(" "+_vm._s(_vm.$t("sections.content"))+" ")])]}}])})],1),_c('li',{class:_vm.$style.item,on:{"mouseover":function($event){_vm.symbol = 'contacto'}}},[_c('router-link',{attrs:{"to":"/#section-contacto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
return [_c('a',{attrs:{"href":href},on:{"click":function($event){_vm.open && _vm.playSound('contacto', 'menu');
              _vm.$router.push(href);
              _vm.$emit('close');}}},[_vm._v(_vm._s(_vm.$t("sections.contact"))+" ")])]}}])})],1)])]),_c('div',{class:[_vm.$style.cell, _vm.$style[_vm.symbol], _vm.symbol && _vm.$style.fadeIn]}),_c('a',{class:_vm.$style.youtube,attrs:{"href":"https://www.youtube.com/channel/UCNx1Vq9fdjNUaccmjXYdg5w","target":"_blank"}},[_vm._v(" Youtube ")])])}
var staticRenderFns = []

export { render, staticRenderFns }